import { LngLat, RouteFeature, RouteOptions, TruckParameters } from "@yandex/ymaps3-types";

export const fetchRoute = async (
  startCoordinates: LngLat,
  endCoordinates: LngLat,
  type: RouteOptions['type'] = 'driving'
): Promise<RouteFeature | undefined> => {
  let truck: TruckParameters | undefined;
  if (type === 'truck') {
    truck = {
      weight: 40,
      maxWeight: 40,
      axleWeight: 10,
      payload: 20,
      height: 4,
      width: 2.5,
      length: 16,
      ecoClass: 4,
      hasTrailer: true
    };
  }
  
  try {
    // Проверка доступности метода route
    if (!ymaps3.route) {
      throw new Error('Route method is not available in ymaps3');
    }

    const routes = await ymaps3.route({
      points: [startCoordinates, endCoordinates], // Start and end points of the route LngLat[]
      type, // Type of the route
      bounds: true, // Flag indicating whether to include route boundaries in the response
      truck
    });

    if (!routes[0]) return;

    const route = routes[0].toRoute();

    if (route.geometry.coordinates.length === 0) return;

    return route;
  } catch (error) {
    console.error("Error fetching route:", error);
    return;
  }
}
import { MuseumType } from "../../types/types";

export const fetchMuseums = async (): Promise<MuseumType[]> => {
  try {
    const response = await fetch("/museums.json");

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data: MuseumType[] = await response.json();
    
    return data;
    
  } catch (error) {
    console.error("Error loading coordinates:", error);
    return [];
  }
}

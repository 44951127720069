import { LngLat, RouteFeature } from "@yandex/ymaps3-types";
import { useEffect, useState } from "react";
import mapStyles from './customization.json';
import './Maps.css';
import { MuseumType } from "../../types/types";
import { LOCATION, lineStyle } from "../../constants";
import { fetchRoute } from "../../servives/route-service/route-service";

type Props = {
  museums: MuseumType[];
};

export const Maps = ({ museums }: Props) => {
  const [museumCords, setMuseumCoords] = useState<LngLat | null>(null);
  const [userPlaceCords, setUserPlaceCoords] = useState<LngLat | null>(null);

  useEffect(() => {
    const initMap = async () => {
      try {
        window.ymaps3.getDefaultConfig().setApikeys({
          router: '93c14f3b-3a0a-4938-995e-cad28ae42e46',
          // suggest: '6350f351-1410-4157-acd3-f09ae91f2c91',
        });
  
        await window.ymaps3.ready;
        const { YMap, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapMarker, YMapControls, YMapFeature, YMapControlButton } = window.ymaps3;
        const { YMapZoomControl } = await window.ymaps3.import('@yandex/ymaps3-controls@0.0.1')
  
        const divMap = document.getElementById('map');
        if (divMap) {
          const map = new YMap(divMap, { location: LOCATION, showScaleInCopyrights: true }, [
            new YMapDefaultSchemeLayer({ customization: mapStyles }),
            new YMapDefaultFeaturesLayer({}),
          ]);
  
          // add zoom control design
          const control = new YMapControls({ position: 'left' });
          control.addChild(
            new YMapZoomControl({
              easing: 'linear',
            })
          );
          map.addChild(control);
  
          if (museums) {
            museums.forEach((item: MuseumType) => {
              const markerElement = document.createElement('img');
              markerElement.className = 'marker';
              markerElement.src = 'museum.svg';
              const marker = new YMapMarker({ coordinates: item.coordinates }, markerElement);
              map.addChild(marker);
  
              markerElement.addEventListener('mouseenter', () => {
                markerElement.src = 'museum_hover.svg';
              });
  
              markerElement.addEventListener('mouseleave', () => {
                markerElement.src = 'museum.svg';
              });
  
              markerElement.addEventListener('click', () => {
                setMuseumCoords(marker.coordinates);
                markerElement.src = 'museum_hover.svg';
              });
            });
  
            const textInput = document.getElementById("search");
            if (textInput) {
              textInput.addEventListener("keydown", async (event) => {
                if (event.keyCode === 13) {
                  try {
                    // @ts-expect-error
                    const res = await window.ymaps3.search({ 'text': textInput.value });
                    const markerElement = document.createElement('img');
                    markerElement.className = 'marker';
                    markerElement.src = 'point.svg';
  
                    if (res[0] && res[0].geometry) {
                      setUserPlaceCoords(res[0].geometry.coordinates);
                      const marker = new YMapMarker({ coordinates: res[0].geometry.coordinates }, markerElement);
                      map.addChild(marker);
                    }
                  } catch (error) {
                    console.error('Error during search:', error);
                  }
                }
              });
            }
  
            const createRoute = () => {
              if (userPlaceCords && museumCords) {
                const routeLine = new YMapFeature({ geometry: { type: 'LineString', coordinates: [] }, style: lineStyle });
                map.addChild(routeLine);
  
                const routeHandler = (newRoute: RouteFeature | undefined) => {
                  if (!newRoute) {
                    alert('Route not found');
                    routeLine.update({ geometry: { type: 'LineString', coordinates: [] } });
                    return;
                  }
  
                  routeLine.update({ geometry: newRoute.geometry });
                  if (newRoute.properties.bounds) {
                    map.setLocation({ bounds: newRoute.properties.bounds, duration: 300 });
                  }
                };
  
                fetchRoute(userPlaceCords, museumCords).then(routeHandler);
  
                const topRightControls = new YMapControls({ position: 'top right' });
                map.addChild(topRightControls);
  
                const topLeftControls = new YMapControls({ position: 'top left' }, [
                  new YMapControlButton({
                    text: 'Driving',
                    onClick: () => fetchRoute(userPlaceCords, museumCords, 'driving').then(routeHandler),
                  }),
                  new YMapControlButton({
                    text: 'Truck',
                    onClick: () => fetchRoute(userPlaceCords, museumCords, 'truck').then(routeHandler),
                  }),
                  new YMapControlButton({
                    text: 'Walking',
                    onClick: () => fetchRoute(userPlaceCords, museumCords, 'walking').then(routeHandler),
                  }),
                  new YMapControlButton({
                    text: 'Transit',
                    onClick: () => fetchRoute(userPlaceCords, museumCords, 'transit').then(routeHandler),
                  }),
                ]);
                map.addChild(topLeftControls);
              }
            };
  
            createRoute();
          }
        }
      } catch (e) {
        console.error('Error initializing map:', e);
      }
    };

    initMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (museumCords && userPlaceCords) {
      // add route type choice instead of 'driving'
      fetchRoute(userPlaceCords, museumCords, 'driving');
    }
  }, [userPlaceCords, museumCords]);

  return (
    <>
      <h1 className="mapHeader">MAPS</h1>
      <div className="mapWrapper">
        <input type="text" id={'search'} />
        <div id="map" className="map"></div>
      </div>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { Maps } from '../features/maps/Maps';
import { fetchMuseums } from '../servives/museums-service/museums-service';
import { MuseumType } from '../types/types';
import { loadMapScript } from '../servives/map-service/map-service';

export function App() {
  const [museumsData, setMuseumsData] = useState<MuseumType[] | null>(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
console.log(window.ymaps3)
  // Load the Yandex Maps script
  useEffect(() => {
    loadMapScript()
      .then(() => setIsMapLoaded(true))
      .catch((error) => {
        console.error('Failed to load Yandex Maps API:', error);
      });
  }, []);

  // Initialize the map or perform any ymaps3 related tasks here
  const init = async () => {
    try {
      if (!window.ymaps3) throw new Error('ymaps3 is not defined');

      await window.ymaps3.ready;
      console.log('Yandex Maps API is ready.');
    } catch (error) {
      console.error('Error initializing map:', error);
    }
  };

  useEffect(() => {
    if (isMapLoaded) {
      init();
    }
  }, [isMapLoaded]);

  // Fetch museums data
  useEffect(() => {
    const loadMuseums = async () => {
      try {
        const data = await fetchMuseums();
        setMuseumsData(data);
      } catch (error) {
        console.error('Error fetching museums data:', error);
      }
    };

    loadMuseums();
  }, []);

  if (!isMapLoaded) {
    return <div>Loading map...</div>;
  }

  if (museumsData) {
    return <Maps museums={museumsData} />;
  } else {
    return <div>No museums data available.</div>;
  }
}

import { DrawingStyle, YMapLocationRequest } from "@yandex/ymaps3-types";

export const lineStyle: DrawingStyle = {
  fillRule: 'nonzero',
  fill: '#333',
  fillOpacity: 0.9,
  stroke: [
      {width: 6, color: '#007afce6'},
      {width: 10, color: '#fff'}
  ]
};

export let InfoMessage = null;

export const LOCATION: YMapLocationRequest = {
  center: [37.623082, 55.75254],
  zoom: 14,
};
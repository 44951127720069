export const loadMapScript = (): Promise<typeof ymaps3> => {
  return new Promise((resolve, reject) => {
    if (window.ymaps3) {
      resolve(window.ymaps3);
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://api-maps.yandex.ru/v3/?apikey=93c14f3b-3a0a-4938-995e-cad28ae42e46&lang=ru_RU';
    script.onload = () => {
      // Check again if ymaps3 is available
      if (window.ymaps3) {
        resolve(window.ymaps3);
      } else {
        reject(new Error('Failed to load ymaps3'));
      }
    };
    script.onerror = () => reject(new Error('Script loading error'));
    document.head.appendChild(script);
  });
};
